body {
    font-family: 'Lato', 'Noto Sans', 'Helvetica', 'Corbel', sans-serif, 'Helvetica', 'Corbel', sans-serif;
    font-display: fallback;
    font-size: 14px;
    margin: 0;
    color: #444;
    line-height: 1.4;
}

/* Basic typography for the entire site */
h1,
h2,
h3,
h4,
h5 {
    font-family: inherit;
    font-weight: bold;
}

h1 {
    margin-bottom: 22px;
}

h3 { 
	font-size: 1.25em;
}

.zsenileszeklogo {
	max-width: 75%; 
	height: auto; 
	width: auto;
	margin: auto;
}

.welcometext {
    font-family: inherit !important;
    font-size: 15px !important;
    font-weight: bold !important;
    line-height: 28px !important;
    margin-top: 10px !important;
	padding: 1.5rem 2rem;
}

.header {
    background-color: rgb(10, 42, 102);
    border-radius: .3rem;
	display: block;
}

.headerbox {
    padding-bottom: 32px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1200px !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 24px !important;
    padding-top: 34px !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}

.categoryheaderbox {
    font-family: Lato, "Noto Sans", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    display: -webkit-inline-box !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
    vertical-align: middle !important;
    align-items: center !important;
}

.categoryheaderlink {
    cursor: pointer !important;
    text-decoration: none !important;
    outline: none !important;
    color: rgba(255,255,255,0.64) !important;
}

.subjectheader {
    font-size: 36px !important;
    line-height: 40px !important;
    color: #FFFFFF !important;
    font-weight: 900 !important;
    font-size: 28px !important;
    line-height: 32px !important;
    margin: 0px !important;
}

.searchbox {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1200px !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}


.card {
    margin: 5px;
}

.card-header h3 {
    font-weight: 700 !important;
    font-size: 16px !important;
    color: rgb(68, 68, 68);
}

.card-header h4 {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: rgb(68, 68, 68);
}

.card-footer {
    text-align: right;
    padding: .25rem 1.25rem;
}

.badge-primary {
    color: #fff;
    background-color: rgb(10, 42, 102);
}

.badge-primary a {
    color: #fff;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 0.25rem;
} 

.footer {
    background-color: #0a2a66 !important;
    clear: both !important;
    color: #ffffff !important;
    position: relative !important;
    padding: 12px 20px !important;
    box-sizing: border-box !important;
    max-width: 1200px !important;
    position: relative !important;
    margin: 0 auto !important;
    padding: 0 20px !important;
}

.footeritems {
    font-family: inherit !important;
    font-size: 12px !important;
    line-height: 14px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 8px 0 !important;
}

.footeritem,
.footeritem a {
    margin-left: 16px !important;
    color: #FFFFFF !important;
}

.video
{
}

.video-embedding
{
	padding: 1.5rem;
	margin-bottom: 0.5rem;
}

.video-description
{
	padding: 1.5rem 1rem;
	margin: 0rem;
}

.btn 
{
	margin: 0.5rem;
}

.hit-card
{
	padding: 5px;
	width: 100%;
	height: 100%;
	color: #444;
	background-color: #e9ecef;
	transition: background-color 0.3s;
}

.hit-card:hover
{
	background-color: #dae0e5;
}

.hit-card a
{
	color: #444;
}

.hit-card a:hover
{
	color: #444;
	text-decoration: none;
}

.hit-card .hit-card-thumbnail
{
	opacity: 0.85;
	width: 198px;
	height: 130px;
	transition: opacity 0.3s;
}

.hit-card:hover .hit-card-thumbnail
{
	opacity: 1.00;
}

.hit-card .hit-card-length
{
	width: 100%;
	margin-bottom: 10px;
	opacity: 0.85;
	transition: opacity 0.3s;
}

.hit-card:hover .hit-card-length
{
	opacity: 1.00;
	z-index: 1;
}

.hit-card-header
{
	font-family: Hind;
	color: rgb(150, 152, 195);
	text-transform: uppercase;
	
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal; /* Override any existing white-space styling */
	line-height: 1.2em; /* Adjust as needed to match your design */
	height: 3.0em; /* Line height multiplied by the number of lines you want */
	margin: 0;
}

.hit-card:hover .hit-card-header
{
	text-decoration: none;
}


.hit-card-text
{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal; /* Override any existing white-space styling */
	line-height: 1.2em; /* Adjust as needed to match your design */
	height: 4.8em; /* Line height multiplied by the number of lines you want */
}

.hit-card-grade
{
	float: right;
}

.ais-InfiniteHits-item
{
	padding: 0;
	border-radius: 0.3rem;
}
