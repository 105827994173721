body {
  font-display: fallback;
  color: #444;
  margin: 0;
  font-family: Lato, Noto Sans, Helvetica, Corbel, sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

h1, h2, h3, h4, h5 {
  font-family: inherit;
  font-weight: bold;
}

h1 {
  margin-bottom: 22px;
}

h3 {
  font-size: 1.25em;
}

.zsenileszeklogo {
  width: auto;
  max-width: 75%;
  height: auto;
  margin: auto;
}

.welcometext {
  padding: 1.5rem 2rem;
  margin-top: 10px !important;
  font-family: inherit !important;
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 28px !important;
}

.header {
  background-color: #0a2a66;
  border-radius: .3rem;
  display: block;
}

.headerbox {
  box-sizing: border-box !important;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 34px 16px 24px !important;
  display: flex !important;
}

.categoryheaderbox {
  vertical-align: middle !important;
  align-items: center !important;
  font-family: Lato, Noto Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  display: inline-flex !important;
}

.categoryheaderlink {
  cursor: pointer !important;
  color: #ffffffa3 !important;
  outline: none !important;
  text-decoration: none !important;
}

.subjectheader {
  color: #fff !important;
  margin: 0 !important;
  font-size: 28px !important;
  font-weight: 900 !important;
  line-height: 32px !important;
}

.searchbox {
  box-sizing: border-box !important;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
}

.card {
  margin: 5px;
}

.card-header h3 {
  color: #444;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.card-header h4 {
  color: #444;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.card-footer {
  text-align: right;
  padding: .25rem 1.25rem;
}

.badge-primary {
  color: #fff;
  background-color: #0a2a66;
}

.badge-primary a {
  color: #fff;
}

.badge-pill {
  border-radius: .25rem;
  padding-left: .6em;
  padding-right: .6em;
}

.footer {
  clear: both !important;
  color: #fff !important;
  box-sizing: border-box !important;
  background-color: #0a2a66 !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  position: relative !important;
}

.footeritems {
  flex-direction: row !important;
  align-items: center !important;
  padding: 8px 0 !important;
  font-family: inherit !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
}

.footeritem, .footeritem a {
  color: #fff !important;
  margin-left: 16px !important;
}

.video-embedding {
  margin-bottom: .5rem;
  padding: 1.5rem;
}

.video-description {
  margin: 0;
  padding: 1.5rem 1rem;
}

.btn {
  margin: .5rem;
}

.hit-card {
  color: #444;
  background-color: #e9ecef;
  width: 100%;
  height: 100%;
  padding: 5px;
  transition: background-color .3s;
}

.hit-card:hover {
  background-color: #dae0e5;
}

.hit-card a {
  color: #444;
}

.hit-card a:hover {
  color: #444;
  text-decoration: none;
}

.hit-card .hit-card-thumbnail {
  opacity: .85;
  width: 198px;
  height: 130px;
  transition: opacity .3s;
}

.hit-card:hover .hit-card-thumbnail {
  opacity: 1;
}

.hit-card .hit-card-length {
  opacity: .85;
  width: 100%;
  margin-bottom: 10px;
  transition: opacity .3s;
}

.hit-card:hover .hit-card-length {
  opacity: 1;
  z-index: 1;
}

.hit-card-header {
  color: #9698c3;
  text-transform: uppercase;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  height: 3em;
  margin: 0;
  font-family: Hind;
  line-height: 1.2em;
  display: -webkit-box;
  overflow: hidden;
}

.hit-card:hover .hit-card-header {
  text-decoration: none;
}

.hit-card-text {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  height: 4.8em;
  line-height: 1.2em;
  display: -webkit-box;
  overflow: hidden;
}

.hit-card-grade {
  float: right;
}

.ais-InfiniteHits-item {
  border-radius: .3rem;
  padding: 0;
}

/*# sourceMappingURL=index.d4f8fdcf.css.map */
